import React from "react";

const Header = props => {
  return (
    <div>
      <nav className="nav-wrapper white">
        <div className="container">
          <a href="#" className="brand-logo">
            <img
              src="https://a.staticaa.com/images/logo/aa-logo.png"
              className="img"
            ></img>
          </a>

          <a href="#" className="sidenav-trigger" data-target="mobile-links">
            <i className="material-icons">menu</i>
          </a>
          <ul className="right hide-on-mid-and-down">
            {props.name != null ? (
              <li>
                <a
                  href="#"
                  style={{
                    color: "#212121",
                    fontSize: "1.0rem",
                    fontFamily: "sans-serif"
                  }}
                >
                    <span className="userInfoBoxLabel whiteText appendRight10 font10 latoBlack textCenter"><span>{props.firstLetter}</span></span>
                   {' '}<span style={{color:"black"}}>Hey {props.name}</span>
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="#"
                  style={{
                    color: "#212121",
                    fontSize: "1.0rem",
                    fontFamily: "sans-serif"
                  }}
                >
                  Login Required
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;

import React, {useEffect, useState} from 'react';
import nodemailer from 'nodemailer';
import M from 'materialize-css';

const TriggerValidation = props => {

    const [spreadsheetUrl, setSpreadsheetUrl] = useState(null);
    const [travelPeriod, setTravelPeriod] = useState(null);
    const [dropdownValue, setDropdownValue] = useState("Select Validation Option");
    const [environmentValue, setEnvironmentValue] = useState(null);
    const [triggerResponse, setTriggerResponse] = useState(null);
    const [pipelineId, setPipelineId] = useState(null);
    const [pipelineInformation, setPipelineInformation] = useState(null);
    const [isValidPipelineId, setIsValidPipelineId] = useState(true);
    const [isValidSheet, setIsValidSheet] = useState(true);

    const handleInputChange = e => {
      e.preventDefault()
      setSpreadsheetUrl(e.target.value)
    }

    const handleTravelPeriodInputChange = e => {
      e.preventDefault();
      setTravelPeriod(e.target.value);
      console.log("date is : ",e.target.value)
    }
    const validateSheet = (url) => {
      url = url.trim();
      var regexp = /https:\/\/docs.google.com\/spreadsheet\/*./;
      return regexp.test(url);
    }


    const checkPipelineStatus = (e) => {
      if(isNaN(pipelineId) || pipelineId==null) {
        setIsValidPipelineId(false);
      }
      else{
        setIsValidPipelineId(true);
        console.log("Pipline Id : ", pipelineId);
      fetch('https://feeprice-dot-airasia-automation-poc.appspot.com/api/v1/trigger-gitlabPipeline/'+pipelineId,{
        method: 'GET',
        headers : {
          "x-auth": localStorage.getItem('token')
          }
      })
        .then( response => response.json())
        .then( data => {
          setPipelineInformation(data);
        })
      }


    }

    const handlePipelineIdInputChange = e => {
      e.preventDefault();
      setPipelineId(e.target.value);

    }

    const changeValidationOption = (e, value) => {
      e.preventDefault();
      setDropdownValue(value);
    }

    const selectEnvironment = (value) => {
      setEnvironmentValue(value);
    }

    const handleFormSubmit = e => {
      let payload = {
        "excelSheet": spreadsheetUrl,
        "validationChoice": dropdownValue,
        "environment": environmentValue,
        "travelPeriod": travelPeriod
      };
      if(!validateSheet(spreadsheetUrl) || spreadsheetUrl == null){
        setIsValidSheet(false);
      }
      else{
        setIsValidSheet(true);
        fetch('https://feeprice-dot-airasia-automation-poc.appspot.com/api/v1/validatefeeprice',
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers : {
          "Content-Type":"application/json",
          "x-auth": localStorage.getItem('token')
        }
      })
      .then( response => response.json())
      .then( data => {
        setTriggerResponse(data);
      })
      }

    }


    useEffect( () => {
      M.AutoInit();
      const Calender = document.querySelector('.datepicker');
      M.Datepicker.init(Calender, {
        format: 'yyyy-mm-dd',
        showClearBtn: true
      });

      document.getElementsByClassName("datepicker-done")[0].addEventListener('click',(event)=>{
        event.preventDefault();
        var datepickerValue = window.$("#travelPeriod").val();
        setTravelPeriod(datepickerValue);

      });

    }, []);

    return (
      <React.Fragment>

      <div className="container userInputSection">
        <h4>Validation Request</h4>
        <hr />
        <div className="row validationSection">
          <form className="col s12">
            <div className="row spreadsheetInput">
              <div className="input-field col s12">
                <input value={spreadsheetUrl === null ? "" : spreadsheetUrl} id="sheetUrl" type="text" className="validate" onChange={handleInputChange}/>
                { !isValidSheet ? <span style={{padding: "1%",background:"antiquewhite",display:"flex",color:"red"}}><i class="material-icons">error_outline</i>{' '}invalid sheet entered</span>: <span></span>}
                <label htmlFor="sheetUrl">Google Spreadsheet Url</label>
              </div>
            </div>
            <div className="row validationOption">
              <a className='dropdown-trigger waves-effect waves-teal btn-flat' href="#" data-target="valdationOption"><span textCenter>{dropdownValue}</span></a>
              { dropdownValue !== "Select Validation Option" ? <i className="material-icons">done</i> : null}
              <ul id="valdationOption" className="dropdown-content validationOptionDropdown">
                <li onClick={e => changeValidationOption(e,"InPath_Baggage_Price_list")}><a href="#!">InPath Baggage Price list</a></li>
                <li onClick={e => changeValidationOption(e,"Manage_my_booking_price_list")}><a href="#!">Manage my booking price list</a></li>
              </ul>
            </div>
            <div className="row">
              <div className="input-field col s6 environmentSelection">
                <select onChange={e => {e.preventDefault();selectEnvironment(e.target.value)}}>
                  <option value="" disabled selected>Choose option</option>
                  <option value="staging" onClick={e => selectEnvironment(e, "staging")}>Staging</option>
                  <option value="production" onClick={e => selectEnvironment(e, "production")}>Production</option>
                </select>
                <label class="selectEnvLabel">Select Environment</label>
              </div>
              <div className="input-field col s5 travelPeriodInput">
                <input value={travelPeriod === null ? "" : travelPeriod} id="travelPeriod" type="text"  className="validate datepicker"/>
                <label htmlFor="travelPeriod" class="travelPeriod">Travel Period</label>
              </div>
            </div>
            <div className="row continueValidation">
              <div className="col s12">
                <button
                  className={ spreadsheetUrl !== null && dropdownValue !== "Select Validation Option" && environmentValue !== null && travelPeriod !== null ? "btn waves-effect waves-light" : "btn waves-effect waves-light disabled"}
                  type="button"
                  name="action"
                  onClick={ e => handleFormSubmit(e)}
                >
                  Proceed Validation
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="triggerResponseData">
          { triggerResponse !== null?
            <div className="center">
              <h6>Job scheduled successfully</h6>
              <h3 style={{color:"green"}}>{triggerResponse.id}</h3>
              <h6>Please note this Job id to track it progress</h6>
            </div>
            : null
          }
        </div>
      </div>
        <div className="container checkPipelineStatus">
          <h4>Check Job Status</h4>
          <hr />
          <div className="row jobStatusSection">
            <div className="col s6">
              <div className="input-field col s8">
                <input value={pipelineId === null ? "" : pipelineId} id="pipelineId" type="text" className="validate" onChange={ e => handlePipelineIdInputChange(e)} />
                { !isValidPipelineId ? <span>Enter a valid pipeline Id</span>: <span></span>}
                <label htmlFor="pipelineId">Job Id</label>
              </div>
              <a
                className="btn waves-effect waves-light input-field "
                onClick={ checkPipelineStatus }
              >
                Check
              </a>
            </div>
            <div className="col s6 jobStatus center">
            { pipelineInformation === null? null :
              [
                (pipelineInformation.status !== "success" ?
                <div><h5 style={{color:"red"}}> Job Status : {pipelineInformation.status} </h5></div>
                :
                [
                  <div>
                    <h5 style={{color:'green'}}> Job Status : {pipelineInformation.status} </h5>
                    <a class="waves-effect waves-light btn viewReportButton" href={'https://storage.googleapis.com/pricevalidation/'+pipelineInformation.id+'/FeePriceValidationReport.html'} target="_blank">View Report</a>
                  </div>
                ]
              )
              ]
            }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
};

export default TriggerValidation;

import React, { useState, useEffect } from "react";
import M from "materialize-css";

const ViewPreviousReports = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);

  const fetchReports = () => {
    setIsLoading(true);
    fetch("https://feeprice-dot-airasia-automation-poc.appspot.com/api/v1/get-pipelines",{
      method : 'GET',
      headers : {
        "x-auth": localStorage.getItem('token')
        }
    })
      .then(response => response.json())
      .then(data => {
        setTimeout(() => {
          setData(data);
          setIsLoading(false);
        }, 3000);
      })
      .catch(error => setError(error));
  };

  useEffect(() => {
    fetchReports();
  }, [isRefresh]);

  return (
    <div className="container reports">
      <div className="row">
        <div className="col s12 l6 " style = {{position : "relative", top : "-25px"}}>
          <h4>Previous Validation Result</h4>
        </div>
      </div>
      <hr />
      <div className="row refreshButton">
        <a
          className="waves-effect waves-light btn right align-top"
          onClick={() => setIsRefresh(!isRefresh)}>
          Refresh{" "}
          <i className="material-icons">refresh</i>
        </a>
      </div>



      {/* <div class="right">
        </div> */}
      {isLoading ? (
        <div className="center-align">

          <h6 className="center-align">Loading</h6>
          <div className="preloader-wrapper active">
            <div className="spinner-layer spinner-dark-cyan-only">
              <div className="circle-clipper left">
                <div className="circle"></div>
              </div>
              <div className="gap-patch">
                <div className="circle"></div>
              </div>
              <div className="circle-clipper right">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <table className="centered highlight responsive-table">
          <thead>
            <tr className="grey">
              <th>
                <span>Pipeline Id</span>
              </th>
              <th>
                <span>Pipeline Status</span>
              </th>
              <th>
                <span>Reports</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => {
              return value.status === "success" ? (
                <tr key={value.id}>
                  <td>
                    <span>{value.id}</span>
                  </td>
                  <td>
                    <i className="material-icons green-text">check_circle </i>
                  </td>
                  <td>
                    <a
                      className="btn viewReportButton"
                      href={
                        "https://storage.googleapis.com/pricevalidation/" +
                        value.id +
                        "/FeePriceValidationReport.html"
                      }
                      target="_blank"
                    >
                      View Report
                    </a>
                  </td>
                </tr>
              ) : value.status === "failed" ? (
                <tr key={value.id}>
                  <td>
                    <span>{value.id}</span>
                  </td>
                  <td>
                    <i className="material-icons red-text">cancel </i>
                  </td>
                  <td>Not Available</td>
                </tr>
              ) : (
                <tr key={value.id}>
                  <td>
                    <span>{value.id}</span>
                  </td>
                  <td>
                    <i className="material-icons orange-text text-darken-1">
                      block{" "}
                    </i>
                  </td>
                  <td>Not Available</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ViewPreviousReports;

import React from "react";
import Header from "./Header";
import TriggerValidation from "./TriggerValidation";
import Footer from "./Footer";
import ViewPreviousReports from "./ViewPreviousReports";
import { GoogleLogin } from "react-google-login";
import { useState, useEffect } from "react";
import { REACT_APP_CLIENT_ID, REACT_APP_SECRET } from '../config/config';
import M from 'materialize-css';
let CryptoJS = require("crypto-js");

const App = props => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [name, setName] = useState(null);
  const [errorLoginAlert, setErrorLoginAlert] = useState(false);
  const [firstLetter, setFirstLetter] = useState(null);

  useEffect(() => {
    if(errorLoginAlert){
      M.toast({html: 'Error Authenticating!<br />Please check', classes: 'rounded'});
    }
  },[errorLoginAlert])

  const responseGoogleSuccess = res => {
        let isTrue = true;
        fetch('https://www.googleapis.com/oauth2/v3/tokeninfo?id_token='+res.tokenId,{
            method: 'GET'
        })
            .then( res => res.json())
            .then( response => {
                localStorage.setItem('token', CryptoJS.AES.encrypt(res.tokenId, REACT_APP_SECRET).toString());
                isTrue = response.hd === "airasia.com"
            })
            .catch( err => {
                console.log("Error in Token Info Call");
            });
        let firstName = res.profileObj.givenName.split(' ')[0];
        let firstLetter = firstName[0];
        setFirstLetter(firstLetter);
        setName(firstName);
        localStorage.setItem("userEmail",res.profileObj.name);
        setErrorLoginAlert(!isTrue);
        setIsSignedIn(isTrue);
    };

    const responseGoogleError = res => {
      console.log("Error authenticating");
      setErrorLoginAlert(true);
    };

  return (
    <div className="App">
      <Header name={name} firstLetter={firstLetter}/>

        <div className={!isSignedIn? "enableBlur":""}>
          <TriggerValidation/>
          <ViewPreviousReports />
        </div>
        {!isSignedIn ? (<div className="googleAuthenticate">
          <div className="row">
            <div className="col s12 m5">
              <div className="card-panel">
                <GoogleLogin
                  clientId={REACT_APP_CLIENT_ID}
                  buttonText="Authenticate"
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleError}
                  cookiePolicy={"single_host_origin"}
                  hostedDomain={'airasia.com'}
                />
                <br />
                <br />
                <p
                  className="text-center fontFamily"
                  style={{ background: "#f3f3ec", padding: "5%" }}
                >
                  This Application is an Internal Tool used by AirAsia.
                  <br />
                  Authenticate with AirAsia Email Address to explore.
                </p>
              </div>
            </div>
          </div>
        </div>) : null}
      <Footer />
    </div>
  );
};

export default App;

import React from 'react';

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            footerText2 : "This website is owned and operated by AirAsia Software Engineering Team (\"AASET\")",
            footerText1 : "©2019 AirAsia Berhad."
        }
    }

    render() {
        return(
        <footer className="page-footer">
            <div className="container">
            <div className="row">
              <div className="col s12 l6">
                <p style={{fontSize:"1.0rem", fontFamily:"monospace", color: "black"}} >{this.state.footerText2}</p>
              </div>
              <div className="col s12 l6 right">
                <p style={{fontSize:"1.0rem", fontFamily:"monospace", color: "black"}} >{this.state.footerText1}</p>
              </div>
          </div>
            </div>
        </footer>

        )
    };


};
